
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Header from '@/components/layout/Header.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import AppearTransition from '@/components/utilities/transitions/AppearTransition.vue';
import { User } from '@/modules/auth/interfaces/user.interface';
import { auth, verifLevels, notifications } from '@/store/namespaces';
import NotifMethods from '@/store/notifications/methods/verificationLevels.methods';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import AuthMethods from '@/store/auth/methods/auth.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { levelNames } from '@/modules/verificationLevels/constants/verifLevels.constants';
import {
    getStatusColor,
    getStatusIcon,
    getStatusName,
} from '@/modules/verificationLevels/functions/verifLevels.functions';
import {
    Limitation,
    LimitationType,
} from '../interfaces/limitations.interface';
import Loader from '@/components/utilities/Loader.vue';
import { Country } from '@/modules/countries/interfaces/countries.interface';
import { VerifLevelReq } from '../interfaces/requirement.interface';
import { LEVEL_LIMITATION_STATUS } from '../emptyStates/verifLevels.emptyState';

@Component({
    components: {
        Header,
        SvgIcon,
        AppearTransition,
        Loader,
    },
})
export default class VerificationLevelsFeatures extends Vue
{
    $refs: any = {};
    levels = LEVEL_LIMITATION_STATUS;
    loading = true;
    tableLimitations = [];
    rowsLimitations = [];
    businessPlan = [];

    async mounted()
    {
        this.loading = true;
        try {
        this.rowsLimitations = this.levels.map((el) => el);
        await this.verifyCurrentLevel();
        let countryId = this.user.id_resid_country;
        if (typeof countryId === 'number') countryId = countryId.toString();
        if (!(await this.fetchLimitations(countryId))) throw new Error();
        this.limitations.name_limitations.forEach((el) =>
        {
            let limitations: any = {};
            limitations.id_limitation = el.id_limitation;
            limitations.name = el.name;
            limitations.limitations = [];
            this.limitations.limitations.forEach((e) =>
            {
                if (e.id_limitation === el.id_limitation)
                {
                    limitations.limitations.push(e);
                }
            });
            this.tableLimitations.push(limitations);
        });
        this.rowsLimitations.forEach((el) =>
        {
            let limitations: any = this.limitations.name_limitations;
            el.limitations = limitations.map((el) => el);
        });
        this.loading = false;
        } catch (e)
        {
            console.error(e);
            console.error(e.message);
            this.$router.push({ name: 'ErrorPage' }).catch();
        }
    }

    goToLink()
    {
        console.log('this.user.id_verif_level', this.user.id_verif_level);
        console.log('this.user.verif_level_apb', this.user.verif_level_apb);
        if (this.user.id_verif_level === 0 && this.user.verif_level_apb === true || this.user.id_verif_level === 1 && this.user.verif_level_apb === false)
        {
            window.location.href = `https://signup.getsilt.com/?company_app_id=${
                    process.env.VUE_APP_HOST.includes('https://api.bithonor.com/')
                        ? process.env.VUE_APP_EUROPE_VERSION &&
                          process.env.VUE_APP_EUROPE_VERSION === 'true'
                            ? process.env.VUE_APP_SILT_ID_PROD_EUROPE
                            : process.env.VUE_APP_SILT_ID_PROD_LATAM
                        : process.env.VUE_APP_SILT_ID_TEST
                }&redirect_url=${
                    process.env.VUE_APP_DOMAIN
                }/tablero&meta={"email_user":"${encodeURIComponent(
                    this.user.email_user
                )}"}`
        } else if (this.user.id_verif_level === 1 && this.user.verif_level_apb === true  || this.user.id_verif_level === 2 && this.user.verif_level_apb === false)
        {
            this.$router.push({ name: 'LevelTwo' });
        }
    }

    getText(level: number, idLimitation: number) {
        return this.limitations.limitations.find((el) => {
            if (
                el.id_limitation === idLimitation &&
                el.id_verification === level
            )
                return el;
        });
    }

    @Watch('user.verif_level_apb')
    async watchVerifications() {
        await this.verifyCurrentLevel();
    }

    resetLevelsStatus() {
        this.levels.forEach((el) => {
            el.currentLevel = false;
            el.approved = undefined;
            el.badge.color = '';
            el.badge.icon = '';
            el.badge.name = '';
        });
    }

    async verifyCurrentLevel() {
        if (!(await this.fetchVerifLevelReqs(this.user.email_user)))
            throw new Error('Error fetching verif level reqs');
        this.resetLevelsStatus();
        if (
            this.verifLevelReqs.level_one === null &&
            this.verifLevelReqs.level_two === null
        ) {
            this.levels[0].badge.color = getStatusColor(true); //this.user.verif_level_apb
            this.levels[0].badge.icon = getStatusIcon(true); //this.user.verif_level_apb
            this.levels[0].badge.name = getStatusName(true); //this.user.verif_level_apb
            this.levels[0].approved = true; //this.user.verif_level_apb;
            // if (this.user.verif_level_apb === true)
            this.levels[1].currentLevel = true;
            // else this.levels[0].currentLevel = true;
        }
        if (this.verifLevelReqs.level_one !== null) {
            let approved = this.setBadges(1, 'level_one');
            this.levels[1].approved = approved;
            this.levels[1].badge.color = getStatusColor(approved);
            this.levels[1].badge.icon = getStatusIcon(approved);
            this.levels[1].badge.name = getStatusName(approved);
            if (approved !== true) this.levels[1].currentLevel = true;
            else this.levels[2].currentLevel = true;
        } else this.levels[1].approved = undefined;
        if (this.verifLevelReqs.level_two !== null) {
            let approved = this.setBadges(2, 'level_two');
            this.levels[2].approved = approved;
            this.levels[2].badge.color = getStatusColor(approved);
            this.levels[2].badge.icon = getStatusIcon(approved);
            this.levels[2].badge.name = getStatusName(approved);
            this.levels[2].currentLevel = true;
        } else this.levels[2].approved = undefined;
    }

    setBadges(verifLevel: number | string, levelName: string): boolean {
        // let approved = true;
        // let isAnyFalse = this.verifLevelReqs[levelName].find(
        //     (el) => el && el.req_confirm_action === 'false'
        // );
        // if (isAnyFalse) approved = false;
        // else {
        //     let isAnyNull = this.verifLevelReqs[levelName].find(
        //         (el) => el && el.req_confirm_action === null
        //     );
        //     if (isAnyNull) approved = null;
        // }

        let approved;

        if (this.verifLevelReqs.user.id_verif_level === verifLevel){
            approved = this.verifLevelReqs.user.verif_level_apb;
        }
        Array.from(Array(verifLevel).keys()).forEach((el) => {
            this.levels[el].badge.color = getStatusColor(true);
            this.levels[el].badge.name = getStatusName(true);
            this.levels[el].badge.icon = getStatusIcon(true);
            this.levels[el].approved = true;
        });
        return approved;
    }

    getCountriesList(countriesList: Country[]) {
        let countriesString = '';
        countriesList.forEach((el) => {
            if (countriesString.length > 0) countriesString += ', ';
            countriesString += el.viewing_name;
        });
        return countriesString;
    }

    isActiveTableItem(level: number, limitation: Limitation): string {
        let classes = '';
        if (level !== 3 && this.levels[level].currentLevel === true)
            classes += 'active';
        if (limitation.is_allowed) classes += ' fs-smaller';
        // if ((level + 1 )=== this.tableLimitations[0].limitations.length) classes+= ' extra'
        return classes;
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    user!: User;
    @verifLevels.Action(VerifLevelMethods.actions.FETCH_LIMITATION_BY_COUNTRY)
    fetchLimitations!: (countryId: string) => Promise<boolean>;
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LIMITATIONS_BY_COUNTRY)
    limitations!: {
        limitations: Limitation[];
        name_limitations: LimitationType[];
    };
    @verifLevels.Action(
        VerifLevelMethods.actions.FETCH_VERIF_LEVEL_REQUIREMENTS
    )
    fetchVerifLevelReqs!: (emailUser: string) => Promise<boolean>;
    @verifLevels.Getter(VerifLevelMethods.getters.GET_VERIF_LEVEL_REQUIREMENTS)
    verifLevelReqs!: {
        level_one: VerifLevelReq[];
        level_two: VerifLevelReq[];
        email_user: string;
        user: any;
    };
    /* NOTIFICATIONS */
    @notifications.Getter(NotifMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[];
}
