import { levelNames } from "../constants/verifLevels.constants";
import { ReqWholeSaleParter } from "../interfaces/requests.interface";

export const REQ_WHOLESALE_PARTNER_EMPTY_STATE: ReqWholeSaleParter ={
    email_user: '',
    reasons : '',
    strenghts: '',
    remittance_service: '',
    old_resid_client_countries: [],	
    old_resid_client_countries_array: [],	
    profession : '',	
    resid_country : undefined,
    migration_status : undefined,
    new_resid_client_countries: [],	
    new_resid_client_countries_array: [],	
    clients_number : 0,	
    monthly_amount : '',	
    monetary_growth : '',	
    clients_growth : 0,	
    bussiness_name : '',	
    web_page_exp : '',	
    logo : '',	
    reasons_status: null,
    strenghts_status: null,
    remittance_service_status: null,
    old_resid_client_countries_status: null,
    profession_status: null,
    resid_country_status: null,
    migration_status_status: null,
    new_resid_client_countries_status: null,
    clients_number_status: null,
    monthly_amount_status: null,
    monetary_growth_status: null,
    clients_growth_status: null,
    bussiness_name_status: null,
    web_page_exp_status: null,
    logo_status: null
}


export const LEVEL_LIMITATION_STATUS : {name: string, link?: string, levelNumber: number,badge: any, currentLevel: boolean, approved: boolean, limitations: Array<any>}[] = [{
    name: levelNames[0],
    levelNumber: 0,
    badge:{
        color: '',
        name: '',
        icon: ''
    },
    currentLevel: false,
    approved: undefined,
    limitations: []
},{
    name: levelNames[1],
    levelNumber: 1,
    link: 'LevelOne',
    badge:{
        color: '',
        name: '',
        icon: ''
    },
    currentLevel: false,
    approved: undefined,
    limitations: []
},{
    name: levelNames[2],
    link: 'LevelTwo',
    levelNumber: 2,
    badge:{
        color: '',
        name: '',
        icon: ''
    },
    currentLevel: false,
    approved: undefined,
    limitations: []
},
]

